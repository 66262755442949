<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Tambah Trainer
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules"
        v-slot="{ invalid }">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Nama lengkap"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Lengkap"
                      rules="required|min:3|max:50|alpha_spaces"
                    >
                      <b-form-input
                        v-model="name"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Skill Role"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Skill Role`"
                      rules="required"
                    >
                      <v-select
                        class="w-100"
                        placeholder="Pilih Skill Role"
                        :label="'name'"
                        :clearable="true"
                        :options="listSkills"
                        :reduce="skill => skill.id"
                        v-model="skillId"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="No hp"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="No Hp"
                      rules="required|min:9|max:15"
                    >
                      <cleave
                        v-model="phone"
                        class="form-control"
                        :options="options.phone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Lokasi"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Lokasi"
                      rules="required"
                    >
                      <v-select
                        v-model="subdistrictId"
                        :reduce="option => option.subdistrict_id"
                        :options="listSubdistrict"
                        :state="
                          errors.length > 0 || submitErrors.province_id
                            ? false
                            : null
                        "
                        placeholder="Ketik untuk mencari..."
                        @search="(search) => search.length > 0 ? onSearchSubdistrict(search) : ''"
                      >
                        <template
                          #selected-option-container="{ option }"
                        >
                          <div class="vs__selected">
                            {{ `${option.subdistrict_name}, ${option.district_name}, ${option.regency_name}, ${option.province_name}` }}
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{
                        errors[0] || submitErrors.province_id
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Detail Alamat"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Detail Alamat"
                      rules="required|min:5"
                    >
                      <b-form-textarea
                        v-model="address"
                        :state="
                          errors.length > 0 || submitErrors.address ? false : null
                        "
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.address
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label="Nama Bank"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Bank"
                      :rules="'required'"
                    >
                      <v-select
                        v-model="bankCode"
                        label="name"
                        :reduce="option => option.code"
                        :options="listBank"
                        :filterable="true"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Ketik untuk mencari..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label="No Rekening"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="No rekening"
                      :rules="'required|numeric'"
                    >
                      <b-form-input
                        v-model="bankNo"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label="Nama Pemilik Rekening"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama pemilik rekening"
                      rules="required|alpha_spaces"
                    >
                      <b-form-input
                        v-model="bankOwnerName"
                        type="text"
                        :formatter="capitalize"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label="NPWP"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="NPWP"
                      rules="numeric"
                    >
                      <b-form-input
                        v-model="npwp"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group
                      label="CV"
                      label-cols-md="4"
                      label-class="font-medium text-7 text-black"
                    >
                      <div class="d-flex gap-10">
                        <div class="w-100">
                          <validation-provider
                            #default="{ errors }"
                            name="CV"
                            rules="ext:pdf"
                          >
                            <b-form-file
                              v-model="fileSelected"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="`Pilih atau drop file disini`"
                              drop-placeholder="Pilih atau drop file disini"
                              accept=".pdf"
                              @change="uploadFile($event)"
                              class="w-100"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                        <div v-if="fileSelected">
                          <BButton
                            v-b-tooltip.hover.top="'Lihat File'"
                            variant="outline-primary"
                            class="ml-auto"
                            target="_blank"
                            :href="fileSelected ? fileUrl(fileSelected) : attachmentInitialFile"
                          >
                            Preview
                          </BButton>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            v-b-modal="'confirmClear'"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalClear @confirm="confirmLeave()"/>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import {
  required,
  min,
  max,
  alpha_spaces,
  numeric,
  ext,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import { capitalize } from '@/libs/helpers'
import ModalClear from './modal/ConfirmClear.vue'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    ModalClear,
  },
  data() {
    return {
      required,
      min,
      max,
      alpha_spaces,
      numeric,
      ext,

      capitalize,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',

      name: '',
      phone: '',
      subdistrictId: '',
      address: '',
      bankCode: '',
      bankNo: '',
      npwp: '',
      bankOwnerName: '',

      listSkills: [],
      listSubdistrict: [],
      listBank: [],
      skillId: null,
      fileSelected: null,

      levelOptions: [
        { text: 'Basic', value: 'basic' },
        { text: 'Spesifik', value: 'specific' },
        { text: 'Pematangan', value: 'mature' },
      ],
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  async mounted() {
    this.getListSkills()
    this.getListBank()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('skill_id', this.skillId)
      formData.append('phone', this.phone)
      formData.append('subdistrict_id', (!Number.isInteger(this.subdistrictId)) ? this.detailTalent.subdistrict_id : this.subdistrictId)
      formData.append('address', this.address)
      formData.append('bank_name', this.bankCode)
      formData.append('bank_account_number', this.bankNo)
      formData.append('bank_account_name', this.bankOwnerName)
      formData.append('npwp', this.npwp)

      if (this.fileSelected) formData.append('cv', this.fileSelected)

      const url = 'v1/trainers/store'
      await komtimAxiosIns
        .post(url, formData)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$router.push({ name: 'instructor' })
        })
        .catch(error => {
          this.alertError(error)

          if (error.response.status === 422) {
            this.submitErrors = Object.fromEntries(
              Object.entries(error.response.data.data).map(
                ([key, value]) => [key, value[0]],
              ),
            )
          }
        })
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListBank() {
      this.offset = 0
      const url = 'v1/bank/available-bank'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listBank = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListSubdistrict(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&keyword=${keyword}`
      const url = `v1/region/villages?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listSubdistrict = data
          this.listSubdistrict = this.listSubdistrict.map(item => {
            const result = {
              ...item,
              label: `${item.subdistrict_name}, ${item.district_name}, ${item.regency_name}, ${item.province_name}`,
            }
            return result
          })
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchSubdistrict: _.debounce((search, it) => {
      it.getListSubdistrict(search)
    }, 500),
    onSearchSubdistrict(search) {
      this.searchSubdistrict(search, this)
    },
    alertError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text: err.response.data.meta.message,
          variant: 'danger',
        },
      }, 1000)
    },
    alertSuccess(text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Success',
            text,
            variant: 'success',
            icon: 'CheckIcon',
          },
        },
        { timeout: 2500 },
      )
    },
    uploadFile(e) {
      const fileSelected = e.target.value
      const fileExtension = fileSelected.substr((fileSelected.lastIndexOf('.') + 1))
      if (fileExtension !== 'pdf') {
        this.fileSelected = null
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi .pdf')
      }
    },
    confirmLeave() {
      this.$router.back()
    },
    fileUrl: file => URL.createObjectURL(file),
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
@import './style/Index.scss';
</style>
